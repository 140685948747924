body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.passwordDisplayContainer {
  width: 80%;
  overflow: hidden;
  margin: 0 auto;
}

.passwordInput {
  min-height: 70px;
}

.passwordDisplay {
  width: 100%;
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(0, 0, 0, 1) 8%,
    rgba(0, 0, 0, 1) 92%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgb(2, 0, 36);
  min-height: 36px;
}

.copyNotification {
  float: right;
  margin-top: -25px;
  margin-right: 8px;
}

.copyNotificationHide {
  visibility: none;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s ease-in-out;
}

.copyNotificationShow {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.copyContainer {
  top: 12px;
}

.securityContainer {
  top: 13px;
  margin-left: 1.1rem;
}

.securityScoreSVG {
  transition: color 0.25s ease;
}

@media (max-width: 650px) {
  .settings-flex {
    flex-direction: column;
  }

  .checkbox-container-1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 14px;
  }

  .checkbox-container-2 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: -8px;
    margin-bottom: 20px;
  }

  .checkbox-container-2-span {
    display: none;
  }

  .cardBorder {
    border: none; 
    box-shadow: none!important;
    padding-bottom: 4rem !important;
    padding-top: 2rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .slider-container {
    padding-right: 23px !important;
    padding-left: 22px !important;
  }

  .checkbox-container {
    padding-left: 22px !important;
  }

  .form-checkbox {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }

  .generatePasswordButton {
      height: 60px;
  }

  .passwordDisplayContainer {
    width: 68%;
  }
}

@media (max-width: 375px) {
  .passwordDisplayContainer {
    width: 63%;
  }
}




/* Extra small devices (portrait phones, less than 544px) */
/* Small devices (landscape phones, 544px and up) */
/* Medium devices (tablets, 768px and up) */
/* Large devices (desktops, 992px and up) */
/* Extra large devices (large desktops, 1200px and up) */

/* Specific Sizes */
/*  XS Size */
@media (max-width: 544px) {}

/*  S Size */
@media (min-width: 544px) and (max-width: 768px) {}

/*  Medium */
@media (min-width: 768px) and (max-width: 992px) {}

/* Large */
@media (min-width: 992px) and (max-width: 1200px) {}

/* Full Size - Extra Large Desktop and Up */
@media (min-width: 1200px) {}

/*Under Sizes*/
/* Everything under medium - XS and S Size - Mobile*/
@media (max-width: 768px) {}

/* Everything Under Large Size */
@media (max-width: 992px) {}

/* Everything Under Extra Large Size */
@media (max-width: 1200px) {}

/*Over Sizes*/
/* Everything Over Small Size */
@media (min-width: 544px) {}

/* Everything Over Medium Tablet Size */
@media (min-width: 768px) {}

/* Everything Over Large Size */
@media (min-width: 992px) {}


